<template>
  <v-dialog transition="dialog-top-transition" max-width="800" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on"> Complete Review </v-btn>
    </template>
    <v-card>
      <v-system-bar
        :height="$vuetify.breakpoint.xs ? 75 : 50"
        color="primary"
        class="white--text elevation-5"
      >
        <span class="text-h6 pl-2">Complete {{ name }}'s Resume Review</span>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <div class="pt-4 pb-3 px-6">
        <v-form ref="form" @submit.prevent="submit()">
          <p class="text-body-1">
            {{ name }} will recieve a notification that their resume has been
            reviewed.
          </p>
          <p class="text-body-1">
            The Career Center will not be able to add any additional comments
            after the review is complete.
          </p>
          <v-text-field
            label="Reviewed By"
            v-model="reviewer"
            disabled
          ></v-text-field>
          <v-text-field
            disabled
            label="Date Reviewed"
            v-model="curDate"
          ></v-text-field>
          <div class="d-flex justify-space-between">
            <v-btn type="submit" color="accent">Complete Review</v-btn>
            <v-btn @click="dialog = false" outlined>Cancel</v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import * as log from "../utility/logging/logger";
import * as adminApi from "../api/CareerApiAdmin";

// @ is an alias to /src
export default {
  name: "SubmitReview",
  data: () => ({
    dialog: false,
    curDate: "",
    resumeName: "",
    resumeNote: "",
  }),
  props: {
    name: {
      type: String,
      default: "Student",
    },
    reviewer: {
      type: String,
      default: "",
    },
    reviewUserId: {
      type: String,
    },
    reviewId: {
      type: String,
    },
  },
  mounted() {
    let d = new Date();
    this.curDate = d.toLocaleDateString("en-us");
  },
  computed: {},
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    async submit() {
      try {
        var result = await adminApi.CompleteReview(
          this.reviewUserId,
          this.reviewId
        );
        if (!result.success) {
          log.logError(
            `Unable to complete resume revue ${this.reviewUserId}`,
            "SubmitReview.submit"
          );
          this.$emit(
            "error",
            "Unable to complete the review. Please try again."
          );
          this.dialog = false;
          return;
        }
        this.nav("/ActiveReviewList");
      } catch (er) {
        log.logError(
          `Unhandled error - Unable to complete resume revue ${this.reviewUserId}`,
          "SubmitReview.submit"
        );
        this.$emit("error", "Unable to complete the review. Please try again.");
      }
    },
  },
};
</script>